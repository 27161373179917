<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ cardTitle }}</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        v-if="isMostRequestedServicesLoading"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 10vh"
      >
        <span class="font-small-4">Chargement...</span>&nbsp;
        <b-spinner
          style="width: 2.5rem; height: 2.5rem"
          class="align-middle text-info"
        />
      </div>
      <div
        v-else-if="isMostRequestedServicesLoading == false && data.length == 0"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 10vh"
      >
        <span class="font-small-4">Aucun service</span>&nbsp;
      </div>
      <div
        v-else
        v-for="(item, index) in data"
        
        :key="index"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="65"
              :src="item.image"
            >
              <feather-icon
                v-if="item.image == '' "
                size="24"
                icon="ShoppingBagIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ item.content }}
            </h6>
            <small>{{ item.subcontent }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder text-info font-small-4">
          {{ item.total }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  // BDropdown,
  BSpinner,
  // BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BSpinner,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    cardTitle: {
      type: String,
      default: 'Title',
    },
  },
  computed: {
    ...mapGetters(['isMostRequestedServicesLoading', ]),
    
  }
}
</script>
