<template>
  <section id="dashboard-analytics">
    <div>
      
      <b-row>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
        
       

          <!-- <b-card
          v-else-if="getUser.role.slug === 'charge-de-clientele'"
            title="STATISTIQUES"
          >
            <b-row class="justify-content-center mx-1 mb-3">
              <b-col
                sm="9"
                xs="12"
              >
                <b-form-group>
                  <h5>Filtrer par date</h5>
                  <flat-pickr
                    v-model="rangeDate"
                    class="form-control"
                    :config="config"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="3"
                xs="12"
                class="mt-2"
              >
                <b-button
                  :disabled="isRangeStatisticsLoading || rangeDate == null"
                  variant="primary"
                  @click="
                    rangeDate = null;
                    filterIsActive = false;
                    getAdminOrderKpis();
                  "
                >
                  {{ isRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
                </b-button>
              </b-col>
            </b-row>

            <div
              v-if="isAdminOrderKpisLoading"
              class="text-center text-info my-2"
            >
              <b-spinner
                variant="info"
                style="width: 3rem; height: 3rem"
              />
            </div>

            <b-row v-else>
              <b-col
                v-for="(item, index) in Object.entries(statisticsItems)"
                :key="index"
                lg="4"
                sm="6"
                style="height: max-content !important"
              >
                <statistic-card-horizontal
                  :icon="
                    item[0] === 'numberOfActiveContracts'
                      ? 'PowerIcon'
                      : item[0] === 'commissionPunctualOrder'
                        ? 'RotateCwIcon'
                        : item[0] === 'numberOfCompletedPunctualOrder'
                          ? 'CheckIcon'
                          : item[0] === 'numberOfEmployeesDeployed'
                            ? 'UserIcon'
                            : item[0] === 'numberOfFinishedContract'
                              ? 'ShieldOffIcon'
                              : item[0] === 'numberOfPunctualOrder'
                                ? 'RotateCwIcon'
                                : item[0] === 'numberOfRecurringOrderBroughtByCC'
                                  ? 'RepeatIcon'
                                  : item[0] === 'numberOfRegisteredCustomers'
                                    ? 'UsersIcon'
                                    : item[0] === 'numberOfRegisteredPros'
                                      ? 'UsersIcon'
                                      : item[0] === 'numberOfSuspendedContract'
                                        ? 'DeleteIcon'
                                        : item[0] === 'numberOfEmployeesRegistered'
                                          ? 'UserIcon'
                                          : item[0] === 'numberOfEmployeesValidated'
                                            ? 'UserCheckIcon'
                                            : item[0] === 'numberOfEmployeesWhoQuitTheirJobs'
                                              ? 'UserMinusIcon'
                                              : item[0] === 'numberOfRecurringOrder'
                                                ? 'RepeatIcon'
                                                : ''
                  "
                  color="info"
                  :statistic="item[1].toLocaleString()"
                  :statistic-title="
                    item[0] === 'numberOfActiveContracts'
                      ? 'Contrat(s) actif(s)'
                      : item[0] === 'numberOfEmployeesWhoQuitTheirJobs'
                        ? 'Employé(s) désisté(s)'
                        : item[0] === 'numberOfFinishedContract'
                          ? 'Contrat(s) clôturé(s)'
                          : item[0] === 'numberOfRecurringOrder'
                            ? 'Commande(s) récurrente(s)'
                            : item[0] === 'numberOfSuspendedContract'
                              ? 'Contrat(s) résilié(s)'
                              : item[0] === 'numberOfRecurringOrderBroughtByCC'
                                ? 'Commandes récurrentes apportées'
                                : item[0] === 'commissionPunctualOrder'
                                  ? 'Commission commandes ponctuel'
                                  : item[0] === 'numberOfCompletedPunctualOrder'
                                    ? 'Commandes ponctuelles terminées'
                                    : ''
                  "
                />
              </b-col>
            </b-row>
          </b-card> -->
        </b-col>
      </b-row>
    </div>
    <b-row class="match-height">
      <b-col
        v-if="getUser.role.slug === 'charge-de-clientele' || getUser.role.slug === 'responsable-commercial' "
        lg="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-2"
            >
              <b-button
                v-b-modal.modal-new-wallet-operation
                variant="outline-info"
              >
                <span
                  class="text-wrap font-medium-1"
                >Débit portefeuille</span>
              </b-button>
            </b-col>
            <!-- filter  -->
            <b-col
              cols="12"
              sm="6"
              md="3"
              lg="4"
              class="mb-3"
            >
              <label for="">Employée</label>
              <v-select
                v-model="payloadFilter.employee_id"
                :options="employeeOptions"
                class="invoice-filter-select"
                label="name"
                :reduce="(employee) => employee.id"
              >
                <template v-slot:no-options>
                  <b-spinner
                    v-if="isEmployeesWithoutPaginationLoading"
                    style="width: 2.5rem; height: 2.5rem"
                    class="align-middle text-info"
                  />
                  <span
                    v-else
                    class="mt-1 font-medium-1"
                  >Aucun employée ne correspond</span>
                </template>
              </v-select>
            </b-col>
            <b-col
              col="12"
              md="3"
              class=""
            >
              <b-form-group>
                <h5>Filtrer par date</h5>
                <flat-pickr
                  v-model="payloadFilter.rangeDate"
                  class="form-control"
                  :config="config"
                />
              </b-form-group>
            </b-col>
            <!-- /filter  -->

            <b-col
              cols="12"
              sm="6"
              md="3"
              lg="2"
              class="d-flex align-items-center justify-content-end mt-2 mb-3"
            >
              <b-button
                variant="primary"
                @click="applyFilterEmployeesAction()"
              >
                <div v-if="isReLoadFilterDataEmployees">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>

                <span
                  v-else
                  class="text-nowrap font-medium-1"
                >Filtrer</span>
              </b-button>
            </b-col>

          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-2"
            >
              <b-button
                variant="primary"
                @click="applyGetEmployeesWalletsLogsWallet()"
              >
                <div v-if="isLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span
                  v-else
                  class="text-nowrap"
                >Actualiser</span>
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-2"
            >
              <span
                v-if="solde != null"
                class="text-nowrap"
              >Solde du portefeuille {{ solde }} XOF</span>
            </b-col>
          </b-row>
          <b-table
            ref="refInvoiceListTable"
            :items="getEmployeesWalletsLogs.data != null ? getEmployeesWalletsLogs.data : getEmployeesWalletsLogs"
            responsive
            :busy="isEmployeesWalletsLogsLoading"
            :fields="tableWalletLogsColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune opération effectuée."
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- Column: Issued Date -->
            <template #cell(wallet)="data">
              <span class="font-small-3 text-info">
                {{
                  data.item.wallet != null
                    ? data.item.wallet.employee.full_name
                    : "Employé supprimé"
                }}
              </span>
            </template>
            <template #cell(amount)="data">
              <span class="text-success font-small-3">
                XOF {{ data.item.amount }}
              </span>
            </template>
            <template #cell(operation_date)="data">
              <span class="font-small-3">
                {{
                  moment(data.item.operation_date)
                    .locale("fr")
                    .format("llll")
                    .split("00:00")[0]
                }}
              </span>
            </template>
            <template #cell(balance_after_operation)="data">
              <span class="font-small-3 text-info">
                {{

                  data.item.balance_after_operation
                }}
                XOF
              </span>
            </template>
          </b-table>

          <div class="d-flex justify-content-between mt-2 flex-wrap">
            <b-button
              :disabled="metaData.prev_page_url == null"
              variant="outline-secondary"
              @click="
                applyLoadMoreEmployeesWalletLogsAction(metaData.prev_page_url)
              "
            >
              <span
                class="text-white text-nowrap font-medium-2"
              >Page précédente</span>
            </b-button>

            <div>
              <span
                class="text-white font-medium-2"
              >{{ metaData.current_page }} sur
                {{ metaData.last_page }} pages</span>
            </div>
            <div>
              <span class="text-white font-medium-2">Total : {{ total }}</span>
            </div>
            <b-button
              variant="outline-secondary"
              :disabled="metaData.next_page_url == null"
              @click="
                applyLoadMoreEmployeesWalletLogsAction(metaData.next_page_url)
              "
            >
              <span
                class="text-nowrap font-medium-1 text-white"
              >Page suivante</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="4">
        <vedette-card
          card-title="Services récurrent vedettes"
          :data="mostRequestedRecurringServices"
        />
      </b-col>

      <b-col lg="4">
        <vedette-card
          card-title="Services ponctuel vedettes"
          :data="mostRequestedServices"
        />
      </b-col>

      <b-col lg="4">
        <vedette-card
          card-title="Profesionnels vedettes"
          :data="bestPro"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modal-new-wallet-operation"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Débit portefeuille"
    >
      <validation-observer
        #default="{}"
        ref="paymentForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyEmployeeWalletOperationAction"
        >
          <b-form-group
            label="Choisissez l'employé"
            label-for="client"
          >
            <validation-provider
              #default="{ errors }"
              name="employée"
              rules="required"
            >
              <v-select
                v-model="payload.employee_id"
                :options="employeeOptions"
                class="invoice-filter-select"
                label="name"
                :reduce="(employee) => employee.id"
              >
                <template v-slot:no-options>
                  <b-spinner
                    v-if="isEmployeesWithoutPaginationLoading"
                    style="width: 2.5rem; height: 2.5rem"
                    class="align-middle text-info"
                  />
                  <span
                    v-else
                    class="mt-1 font-medium-1"
                  >Aucun employé ne correspond</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="designation"
            rules="required"
          >
            <b-form-group
              label="Libellé"
              label-for="designation"
            >
              <b-form-input
                id="designation"
                v-model="payload.designation"
                type="text"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="amount"
            rules="required"
          >
            <b-form-group
              label="Montant"
              label-for="amount"
            >
              <b-form-input
                id="amount-add"
                v-model="payload.amount"
                type="number"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isPaymentLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isPaymentLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Confirmer</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormGroup,
  BSpinner,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, alphaNum } from '@validations'
import VedetteCard from './VedetteCard.vue'
import StatisticsCard from '../statistics/StatisticsCard.vue'

export default {
  components: {
    BButton,
    BRow,
    BSpinner,
    BCol,
    BTable,
    flatPickr,
    vSelect,
    BForm,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    VedetteCard,
    StatisticsCard,
    ValidationProvider,
    ValidationObserver,
    StatisticCardHorizontal,
  },
  data() {
    return {
     
      caId: null,
      payloadFilter: {
        employee_id: '',
        rangeDate: '',
      },
      
      isPaymentLoading: false,
      isReLoadFilterDataEmployees: false,
      rangeDate: null,
      filterIsActive: false,
      statisticsItems: [],
      required,
      solde: null,
      isBusinessManagersKpisLoading: true,
      isBDPKpisLoading: true,
      isRangeStatisticsLoading: false,
      isAdminOrderKpisLoading: true,
      config: { mode: 'range' },
      bdpId: null,
      isLoading: false,
      employeeOptions: [],
      payload: {
        designation: null,
        amount: null,
        operation_type: 'withdraw',
        withdraw_type: '2',
        employee_id: null,
      },
      tableWalletLogsColumns: [
        {
          key: 'wallet',
          label: 'Employée',
          sortable: true,
          class: 'font-small-4',
        },
        {
          key: 'amount',
          label: 'Montant',
          sortable: true,
          class: 'font-small-4',
        },
        {
          key: 'trace',
          label: 'Description',
          sortable: true,
          class: 'font-small-4',
        },
        {
          key: 'operation_date',
          label: 'Date',
          sortable: true,
          class: 'font-medium-2',
        },
        {
          key: 'balance_after_operation',
          label: 'Solde',
          sortable: true,
          class: 'font-medium-2',
        },
      ],
      items: [],
      bestPro: [],
      pointFocauxStat: [],
      mostRequestedServices: [],
      mostRequestedRecurringServices: [],
      total: '',
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),

    ...mapGetters('orders', ['getOrders']),

    ...mapGetters('services', [
      'getMostRequestedServices',
      'getMostRequestedRecurringServices',
    ]),

    ...mapGetters('statistics', [
      'getSatisfiedOrder',
      'getUnsatisfiedOrder',
      'getStatistics',
      'getPointFocauxStat',
    ]),

    ...mapGetters('professionals', [
      'getProfessionals',
      'getBestProfessionals',
      'getProfessionalsPerServicesAndAddress',
      'getEmployeesWithoutPagination',
      'getEmployeesWalletsLogs',
    ]),

    ...mapGetters([
      'isProPerAddressAndServiceLoading',
      'isStatisticsLoading',
      'isEmployeesWithoutPaginationLoading',
      'isEmployeesWalletsLogsLoading',
    ]),

    businessManagerStatisticsItems() {
      return [
        {
          icon: 'FileTextIcon',
          color: 'light-primary',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfActiveContractsCA,
          subtitle: 'Contrats actifs',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-danger',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfTerminatedContracts,
          subtitle: 'Contrats résiliés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.totalAmountOfCommissionCA,
          subtitle: 'Commissions',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.turnoverGrowthRate,
          subtitle: "Croissance du chiffre d'affaire",
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.totalNumberOfInvitedCustomersCA,
          subtitle: 'Clients invités',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'FolderIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.totalNumberOfOrdersInChargeForCA,
          subtitle: 'Commandes récurrentes en charge',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    customerServiceManagerStatisticsItems() {
      return [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics
              .totalNumberOfInvitedCustomersWhoPlacedARecurringOrder,
          subtitle: 'Clients invités ayant passé une Commandes récurrentes',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'CheckSquareIcon',
          color: 'light-danger',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.totalNumberOfPonctualOrdersManagedCompleted,
          subtitle: 'Commandes ponctuelles gérées',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.totalCommissionAmountOfPonctualOrders,
          subtitle: 'Commissions des commandes ponctuelles',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    proBusinessDeveloperStatisticsItems() {
      return [
        {
          icon: 'UserPlusIcon',
          color: 'light-info',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfEmployeesRegisteredPerPeriod,
          subtitle: 'Employés inscrits',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfEmployeesValidatedPerPeriod,
          subtitle: 'Employés validés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserXIcon',
          color: 'light-danger',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics
              .numberOfEmployeesWhoHaveWithdrawnFromWorkPerPeriod,
          subtitle: 'Employés désisté',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'NavigationIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfEmployeesDeployedPerPeriod,
          subtitle: 'Employés déployés',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserPlusIcon',
          color: 'light-info',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfProRegisteredPerPeriod,
          subtitle: 'Pros inscrits',
          customClass: 'mb-2 mb-xl-2',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: this.isStatisticsLoading
            ? '...'
            : this.getStatistics.numberOfProValidatedPerPeriod,
          subtitle: 'Pros validés',
          customClass: 'mb-2 mb-xl-2',
        },
      ]
    },

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getEmployeesWalletsLogs.length != 0) {
        meta.prev_page_url = this.getEmployeesWalletsLogs.prev_page_url
        meta.next_page_url = this.getEmployeesWalletsLogs.next_page_url
        meta.current_page = this.getEmployeesWalletsLogs.current_page
        meta.last_page = this.getEmployeesWalletsLogs.last_page
        meta.current_page_url = `${this.getEmployeesWalletsLogs.path}?page=${this.getEmployeesWalletsLogs.current_page}`
        this.total = this.getEmployeesWalletsLogs.total
      }
      return meta
    },
  },
  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },
    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
    rangeDate(val) {
      if (val && val.split('to').length === 2) {
        this.applyGetRangeStatisticsAction(val)
      }
    },
    isProPerAddressAndServiceLoading(val) {
      if (val === false) {
      }
    },
    getBestProfessionals(val) {
      val.forEach(element => {
        const customData = {
          image: element.profile_image,
          content: element.full_name,
          subcontent: element.service_name,
          total: element.total_works,
        }
        this.bestPro.push(customData)
      })
    },
    getPointFocauxStat(val) {
      val.forEach(element => {
        const customData = {
          image:
            'https://st.depositphotos.com/1719930/2223/i/600/depositphotos_22230607-stock-photo-isolated-check-pictogram-inside-a.jpg',
          content: element.responsable_name,
          subcontent: element.town_name,
          total: element.count,
        }
        this.pointFocauxStat.push(customData)
      })
    },
    getMostRequestedServices(val) {
      val.forEach(element => {
        const customData = {
          image: element.image,
          content: element.name,
          subcontent: '',
          total: element.total_request,
        }
        this.mostRequestedServices.push(customData)
      })
    },
    getMostRequestedRecurringServices(val) {
      val.forEach(element => {
        const customData = {
          image: element.image,
          content: element.name,
          subcontent: '',
          total: element.total_request,
        }
        this.mostRequestedRecurringServices.push(customData)
      })
    },
  },
  created() {
    this.getPointFocauxStatisticsAction()
    if (this.isProPerAddressAndServiceLoading === false) {
    }
    this.getMostRequestedServices.forEach(element => {
      const customData = {
        image: element.image,
        content: element.name,
        subcontent: '',
        total: element.total_request,
      }
      this.mostRequestedServices.push(customData)
    })
    this.getMostRequestedRecurringServices.forEach(element => {
      const customData = {
        image: element.image,
        content: element.name,
        subcontent: '',
        total: element.total_request,
      }
      this.mostRequestedRecurringServices.push(customData)
    })
    this.getBestProfessionals.forEach(element => {
      const customData = {
        image: element.profile_image,
        content: element.full_name,
        subcontent: element.service_name,
        total: element.total_works,
      }
      this.bestPro.push(customData)
    })
    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }

    if (this.getUser.role.slug === 'charge-daffaires') {
      this.getBusinessManagerKpis(this.getUser.id)
    }

    if (this.getUser.role.slug === 'business-developer-pro') {
      this.getBDPKpis(this.getUser.id)
    }

    if (this.getUser.role.slug === 'charge-de-clientele') {
      this.getAdminOrderKpis()
    }
  },
  methods: {
    ...mapActions('statistics', [
      'getBusinessManagerStatisticsAction',
      'getBusinessDeveloperStatisticsAction',
      'getPointFocauxStatisticsAction',
      'getBusinessManagerStatisticsByRangeAction',
      'getAdminOrderStatisticsAction',
      'getAdminOrderStatisticsByRangeAction',
    ]),

    ...mapActions('professionals', [
      'employeeWalletOperationAction',
      'getEmployeesWalletsLogsAction',
      'filterEmployeesWalletsLogsAction',
      'loadMoreEmployeesWalletLogsAction',
    ]),
    applyFilterEmployeesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterDataEmployees = true
        this.solde = null
        const payload = {
          employee_id: this.payloadFilter.employee_id,
          start_date: this.payloadFilter.rangeDate != '' ? this.payloadFilter.rangeDate.split('to')[0] : '',
          end_date: this.payloadFilter.rangeDate != '' ? this.payloadFilter.rangeDate.split('to')[1] : '',
        }

        this.filterEmployeesWalletsLogsAction(payload)
          .then(response => {
            this.isReLoadFilterDataEmployees = false
            this.total = response.data.length
            this.solde = this.payloadFilter.employee_id != '' ? response.data[0].wallet.balance : null
          })
          .catch(error => {
            this.isReLoadFilterDataEmployees = false
          })
      }
    },
    getBDPKpis(id) {
      this.bdpId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isBDPKpisLoading = true
      this.getBusinessDeveloperStatisticsAction(id).then(response => {
        this.isBDPKpisLoading = false
        this.statisticsItems = response.data
      })
    },
    applyEmployeeWalletOperationAction() {
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          this.isPaymentLoading = true

          this.employeeWalletOperationAction({
            id: this.payload.employee_id,
            payload: this.payload,
          })
            .then(response => {
              this.isPaymentLoading = false
              this.applyGetEmployeesWalletsLogsWallet()
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: 'Débit bien effectué',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModal()
            })
            .catch(error => {
              console.log(error)
              this.isPaymentLoading = false
            })
        }
      })
    },
    hideModal() {
      this.$bvModal.hide('modal-new-wallet-operation')
    },
    applyLoadMoreEmployeesWalletLogsAction(url) {
      this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', true)
      this.loadMoreEmployeesWalletLogsAction(url)
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
    },
    applyGetEmployeesWalletsLogsWallet() {
      this.payloadFilter = {
        employee_id: '',
        rangeDate: '',
      }
      this.solde = null
      this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', true)
      this.getEmployeesWalletsLogsAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
    },
    getBusinessManagerKpis(id) {
      this.caId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isBusinessManagersKpisLoading = true
      this.getBusinessManagerStatisticsAction(id).then(response => {
        this.isBusinessManagersKpisLoading = false
        this.statisticsItems = response.data
      })
    },

    getAdminOrderKpis() {
      this.rangeDate = null
      this.filterIsActive = false
      this.isAdminOrderKpisLoading = true
      this.getAdminOrderStatisticsAction().then(response => {
        this.isAdminOrderKpisLoading = false
        this.statisticsItems = response.data
      })
    },

    applyGetRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }

      if (this.getUser.role.slug === 'charge-daffaires') {
        this.isRangeStatisticsLoading = true
        this.isBusinessManagersKpisLoading = true
        this.getBusinessManagerStatisticsByRangeAction({
          caId: this.caId,
          payload,
        })
          .then(response => {
            this.isRangeStatisticsLoading = false
            this.isBusinessManagersKpisLoading = false
            this.statisticsItems = response.data
            this.filterIsActive = true
          })
          .catch(error => {
            this.isRangeStatisticsLoading = false
            this.isBusinessManagersKpisLoading = false
            if (error.response.status !== 422) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: 'Erreur lors du filtrage, Réessayer!',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            }
          })
      }

      if (this.getUser.role.slug === 'charge-de-clientele') {
        this.isRangeStatisticsLoading = true
        this.isAdminOrderKpisLoading = true
        this.getAdminOrderStatisticsByRangeAction({ payload })
          .then(response => {
            this.isRangeStatisticsLoading = false
            this.isAdminOrderKpisLoading = false
            this.statisticsItems = response.data
            this.filterIsActive = true
          })
          .catch(error => {
            this.isRangeStatisticsLoading = false
            this.isAdminOrderKpisLoading = false
            if (error.response.status !== 422) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: 'Erreur lors du filtrage, Réessayer!',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
